import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import Article from 'src/smoothr-web-app-core/models/Article';

@Component({
	selector: 'app-coupons-section',
	templateUrl: './coupons-section.component.html',
	styleUrls: ['./coupons-section.component.scss'],
})
export class CouponsSectionComponent implements OnInit {
	@Input() articleList: Article[];
	@Output() code = new EventEmitter<string>();
	couponValue = '';
	couponValueError = false;
	couponForm = new FormControl('', [Validators.required, Validators.min(1000000000000)]);
	isActive = false;
	constructor() {
		this.couponForm.valueChanges.subscribe(v => {
			console.log(v);
			if (v) {
				this.isActive = true;
			} else {
				this.isActive = false;
			}
			this.couponValueError = false;
		});
	}

	ngOnInit() {}
	validate() {
		if (!this.couponValue) {
			return false;
		}
	}
	emitValue() {
		this.couponValueError = false;
		console.log(this.articleList);
		// const foundArticle = this.articleList.find(it => it.gtin == this.couponForm.value);
		// if (!foundArticle) {
		// 	this.couponValueError = true;
		// 	return;
		// }

		this.code.emit(this.couponForm.value);
		this.couponForm.patchValue('');
	}
	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}
}
